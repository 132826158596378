.nuvo-tailwind {
  .data-review .handsontable {
    font-family: var(--globals-font-family);
    color: var(--globals-text-color);
  }

  .data-review .handsontable thead th .relative {
    @apply h-full !p-0;
  }

  .data-review .handsontable thead th .colHeader {
    @apply flex h-full w-full flex-col;
  }

  .data-review .handsontable thead th .colHeader .title-header {
    @apply flex h-full w-auto flex-1 items-center justify-items-start;
  }

  .data-review .handsontable thead tr th:first-child {
    position: relative;
  }

  .data-review .handsontable thead tr th:first-child::before {
    height: 33px;
    z-index: 10;
    @apply text-gray-310 bg-gray-450 border-t-1 absolute left-0 right-0 bottom-0 text-center text-sm font-normal leading-8;
  }

  .data-review .handsontable thead th .colHeader .example-header {
    height: 33px;
    @apply border-t-1 box-border w-full flex-shrink-0 truncate;
  }

  .data-review .handsontable .htAutocomplete {
    position: relative;
    @apply border-gray-250;
  }

  .data-review .handsontable .htAutocompleteArrow {
    max-width: calc(100% - 16px);
    max-height: 28px;
    @apply text-blue-dark-900 !h-full !w-full;
  }

  .data-review .handsontable .htAutocompleteArrow:hover {
    @apply text-blue-dark-900;
  }

  .custom-dropdown-boolean-renderer {
    padding-right: 16px;
  }

  .custom-dropdown-renderer {
    padding-right: 16px;
  }

  .custom-dropdown-renderer-text-element {
    background-color: transparent !important;
    padding-top: 4px;
  }
  .custom-dropdown-boolean-renderer-text-element {
    background-color: transparent !important;
    padding-top: 4px;
  }

  .data-review .hide-arrow-btn {
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #f2f2f2;
    background-color: white;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right-width: 0px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .data-review .hide-arrow-btn.hide-icon-left {
    left: 0;
    right: auto;
    transform: translateY(-50%) rotate(180deg);
  }

  .data-review .handsontable td > div.custom-dropdown-renderer > .close-icon * {
    color: #c5c5c5;
  }

  .data-review
    .handsontable
    td
    > div.custom-dropdown-boolean-renderer
    > .close-icon
    * {
    color: #c5c5c5;
  }

  /* Border select color */
  .data-review
    .handsontable
    td.error-cell.current.highlight
    > div
    > .arrow-down-icon {
    @apply !pl-0;
  }
  /* Border select color */
  .data-review
    .handsontable
    td.warning-cell.current.highlight
    > div
    > .arrow-down-icon {
    @apply !pl-0;
  }
  /* Border select color */
  .data-review
    .handsontable
    td.info-cell.current.highlight
    > div
    > .arrow-down-icon {
    @apply !pl-0;
  }

  .data-review
    .handsontable
    td.default-cell.current.highlight
    > div
    > .arrow-down-icon {
    @apply !pl-0;
  }

  .data-review .handsontable td.error-cell #dropdown-arrow-icon svg {
    @apply text-salmon-500;
  }
  /* Border select color */
  .data-review .handsontable td.warning-cell #dropdown-arrow-icon svg {
    @apply text-yellow-500;
  }
  /* Border select color */
  .data-review .handsontable td.default-cell #dropdown-arrow-icon,
  .data-review .handsontable td.default-cell #dropdown-arrow-icon svg {
    @apply text-blue-dark-900;
  }

  .data-review .handsontable .ht_clone_top_left_corner {
    z-index: 10;
  }

  .data-review .ht_master.handsontable .wtHolder::-webkit-scrollbar {
    @apply !h-2 !w-2;
  }

  .data-review .ht_master.handsontable .wtHolder::-webkit-scrollbar-track {
    background-color: #dfe2e4;
    @apply rounded-medium !w-1.5 bg-clip-padding;
  }

  .data-review .ht_clone_left .wtHolder::-webkit-scrollbar-thumb {
    @apply rounded-medium !bg-gray-300;
  }

  .data-review .ht_master.handsontable .wtHolder::-webkit-scrollbar-thumb {
    @apply rounded-medium !bg-gray-300;
  }

  .data-review::-webkit-scrollbar-thumb {
    background-color: #818b99;
    border: 0px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }

  .data-review .ht_clone_left .wtHolder .wtHider {
    box-sizing: border-box !important;
    @apply !mb-8;
  }

  .data-review.not_firefox .ht_clone_top .wtHolder {
    width: 100% !important;
  }

  .data-review.not_firefox .ht_clone_top {
    width: 100% !important;
  }

  .data-review.not_firefox .add-space-scrollbar-w .ht_clone_top {
    width: calc(100% - 8px) !important;
  }

  .data-review.not_firefox .ht_clone_left.handsontable .wtHolder {
    height: 100% !important;
  }

  .data-review.not_firefox .ht_clone_left:not(.handsontableInputHolder) {
    height: 100% !important;
  }

  .data-review.not_firefox
    .add-space-scrollbar-h
    .ht_clone_left:not(.handsontableInputHolder) {
    height: calc(100% - 8px) !important;
  }

  .data-review .hide-border-last-column .ht_clone_top th:last-child,
  .data-review .hide-border-last-column .ht_clone_top td:last-child,
  .data-review
    div.hide-border-last-column
    .handsontable.ht_master
    tr
    td:last-child {
    border-right-color: transparent !important;
    border-right-width: 0 !important;
  }

  @media only screen and (max-width: 1440px) {
    .data-review .handsontable thead tr th:first-child::before {
      height: 23px !important;
      line-height: 23px !important;
      border-top-width: 1px;
    }

    .data-review .handsontable thead th .colHeader .example-header {
      height: 23px !important;
      line-height: 23px !important;
    }

    .custom-dropdown-renderer-text-element {
      padding-top: 0px;
    }
    .custom-dropdown-boolean-renderer-text-element {
      padding-top: 0px;
    }
  }

  .data-review .wtBorder.fill {
    background: #4b89ff !important;
  }

  .data-review .ht_clone_inline_start th > div.relative {
    @apply flex h-full items-center justify-center;
  }

  .data-review .ht_master.handsontable td,
  .data-review .ht_clone_left.handsontable td {
    padding: 0px 12px !important;
  }

  .data-review .handsontable th .row-header-number {
    display: inline;
  }

  .data-review .handsontable th .relative .rowHeader {
    width: 100%;
  }

  .data-review .nuvo-add-row-button {
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .data-review .nuvo-custom-add-column-button {
    cursor: pointer;
  }

  .data-review .row-header-checkbox {
    @apply bg-white;
    display: none;
    box-shadow: none;
    cursor: pointer;
  }

  .data-review .row-header-checkbox:checked {
    @apply bg-blue-light-900;
  }

  .data-review .handsontable th.hover-row .row-header-number {
    display: none;
  }

  .data-review .handsontable th.hover-row .row-header-checkbox {
    display: block;
  }

  .data-review .handsontable th.checked-row .row-header-checkbox {
    display: block;
  }

  .data-review .handsontable th.checked-row .row-header-number {
    display: none;
  }

  .data-review .check-all-checkbox {
    @apply bg-white;
    cursor: pointer;
    @apply focus:outline-none rounded-medium border-gray-260 !h-4 !w-4 focus:ring-0;
    box-shadow: none !important;
  }

  .data-review .check-all-checkbox:indeterminate {
    @apply border-gray-260 bg-white;
  }

  .data-review .check-all-checkbox:checked {
    @apply bg-blue-light-900 border-transparent;
  }

  .data-review .handsontable thead th .colHeader .context-menu-button {
    cursor: pointer;
  }

  .data-review .handsontable .columnSorting.sortAction:hover {
    cursor: auto;
    text-decoration: initial;
  }

  .data-review .handsontable .columnSorting.sortAction #text-title:hover {
    cursor: var(--text-header-pointer);
    text-decoration: var(--text-header-decoration);
  }

  .data-review .handsontable .columnSorting.sortAction .sort-loader {
    display: none;
  }

  .data-review
    .handsontable
    .sort-loading
    .columnSorting.sortAction
    .sort-loader {
    display: block;
  }

  .data-review
    .handsontable
    .sort-loading
    .columnSorting.sortAction
    .context-menu-button {
    display: none;
  }

  .data-review .handsontable .header-circle-loader {
    border-width: 3px;
    border-style: solid;
    border-radius: 50%;
    width: 13px;
    height: 13px;
    animation: spin 1.2s linear infinite;
    position: relative;
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    top: 2px;
    margin-left: 1px;
  }

  .data-review .ht_clone_left .wtHolder .htCore {
    box-shadow: none;
  }

  /* NOTE: filter-value-list-scroll scrollbar */

  .filter-value-list-scroll .simplebar-scrollbar {
    top: 0;
    height: 5px;
  }
  .filter-value-list-scroll .simplebar-scrollbar::before {
    inset: 0;
  }

  .filter-value-list-scroll .simplebar-track {
    background-color: #f4f4f4;
    border-radius: 60px;
    pointer-events: auto;
  }

  .filter-value-list-scroll .simplebar-track > .simplebar-scrollbar::before {
    background-color: #b9b9b9;
    border-radius: 60px;
    opacity: 1 !important;
  }

  .filter-value-list-scroll .simplebar-track.simplebar-vertical {
    width: 4px;
    top: 0px;
    bottom: 0px;
    transform: translateX(-8px);
  }

  .filter-value-list-scroll
    .simplebar-track.simplebar-vertical
    .simplebar-scrollbar::before {
    inset: 0;
  }

  .filter-value-list-scroll .simplebar-track.simplebar-horizontal {
    height: 5px;
    transform: translateY(5px);
  }

  .filter-value-list-scroll
    .simplebar-track.simplebar-horizontal
    .simplebar-scrollbar {
    top: 0px;
    height: 5px;
  }

  .filter-value-list-scroll
    .simplebar-track.simplebar-horizontal
    .simplebar-scrollbar::before {
    inset: 0;
  }

  /* NOTE: context-menu scrollbar */

  .context-menu-scroll > div > .simplebar-track .simplebar-scrollbar {
    top: 0;
    height: 5px;
  }
  .context-menu-scroll > div > .simplebar-track .simplebar-scrollbar::before {
    inset: 0;
  }

  .context-menu-scroll > div > .simplebar-track {
    background-color: #f4f4f4;
    border-radius: 60px;
    pointer-events: auto;
  }

  .context-menu-scroll > div > .simplebar-track > .simplebar-scrollbar::before {
    background-color: #b9b9b9;
    border-radius: 60px;
    opacity: 1 !important;
  }

  .context-menu-scroll > div > .simplebar-track.simplebar-vertical {
    width: 5px;
    top: 6px;
    bottom: 6px;
    transform: translateX(-4px);
  }

  .context-menu-scroll
    > div
    > .simplebar-track.simplebar-vertical
    .simplebar-scrollbar::before {
    inset: 0;
  }

  .context-menu-scroll > div > .simplebar-track.simplebar-horizontal {
    height: 5px;
    transform: translateY(5px);
  }

  .context-menu-scroll
    > div
    > .simplebar-track.simplebar-horizontal
    .simplebar-scrollbar {
    top: 0px;
    height: 5px;
  }

  .context-menu-scroll
    > div
    .simplebar-track.simplebar-horizontal
    .simplebar-scrollbar::before {
    inset: 0;
  }
}
