@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');

.nuvo-tailwind {
  @apply text-gray-650;
  @apply text-base;

  font-family: var(--globals-font-family);
  color: var(--globals-text-color);
  font-size: 16px;

  .text-color-primary {
    color: var(--text-color-primary);
  }

  .text-color-secondary {
    color: var(--text-color-secondary);
  }

  [role='gridcell'] {
    padding: 0px;
  }

  [role='gridcell'][aria-selected='true'] > .cell-viewer {
    box-shadow: inset 0 0 0 2px var(--rdg-selection-color);
  }
}

.nuvo-ReactModal__Body--open {
  overflow: hidden;
}
